import React from "react";
import MUITableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


const TableHead = (props) => {
  const {
    headCells
  } = props;

  return (
    <MUITableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{backgroundColor: '#1c2024'}}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  );
};

export default TableHead;
