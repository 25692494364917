import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectStandard = ({ label, days, menuItems, handleChange }) => {
  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={days}
          onChange={handleChange}
          label={label}
        >
          {menuItems?.map(({ label, value }, ind) => (
            <MenuItem key={ind} value={value}>
              {label}
            </MenuItem>
          ))}
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectStandard;
