import React from "react";
import TableContainer from "@mui/material/TableContainer";
import MUITable from "@mui/material/Table";
import TableHead from "./TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";


const Table = ({headCells, loading, tableBodyContent}) => {
  return (
    <TableContainer sx={{ height: "calc(100% - 48px)" }}>
      <MUITable
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        stickyHeader
      >
        <TableHead headCells={headCells} />
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {tableBodyContent()}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

export default Table;
