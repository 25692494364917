import { createBrowserRouter } from "react-router-dom";
import LoginInSide from "../components/home";
import Dashboard from "../components/dashboard";
import Users  from "../components/dashboard/users";
import Contracts from "../components/dashboard/contracts";
import Ambassador from "../components/dashboard/ambassador";
import Community from "../components/dashboard/Community";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginInSide />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      { path: "/dashboard/users", element: <Users /> },
      { path: "/dashboard/contracts", element: <Contracts /> },
      { path: "/dashboard/ambassadors", element: <Ambassador /> },
      { path: "/dashboard/ambassadors/:referralId", element: <Community/> },
    ],
  },
]);

export default router;
