import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from "@mui/material/styles";


import useCommon from "../../../hooks/common";
import Table from "../../Table";
import { getRequest } from "../../../utils/httpRequest";

const headCells = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No.",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Joined At",
  },
  {
    id: "updatedAt",
    numeric: true,
    disablePadding: false,
    label: "Last Update",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.strikethrough > td': {
    textDecoration: 'line-through',
    color: '#fa4e4e'
}
}));

const Ambassador = () => {
  const {
    loading,
    setLoading,
  } = useCommon();
  const navigate = useNavigate();
  const [ambassadors, setAmbassadors] = useState([]);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const getAmbassador = async () => {
      try {
        setLoading(true);
        let queryParams = "size=0&role_in=admin,ambassador";
        const {
          code,
          data,
          message = "",
        } = await getRequest({
          url: `/api/v1/ap/users/getUsers`,
          params: queryParams,
        });
        if (code === 200) {
          const ambassadors = (data || []).filter(
            (data) => data?.role?.includes("ambassador")
          );
          const admins = (data || []).filter((data) => data?.role?.includes("admin"));
          setAmbassadors(ambassadors);
          setAdmins(admins);
          return;
        }
        throw new Error(message);
      } catch (error) {
        console.log("============>", error.message);
      } finally {
        setLoading(false);
      }
    };
    getAmbassador();
  }, []);

  const handleRouteToCommunity = ({referralId}) => {
    navigate(`/dashboard/ambassadors/${referralId}`);
  }

  const tableBodyContent = (data) =>
    data?.map((row, index) => {
      return (
        <StyledTableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          className={row?.accountStatus === 'disabled' ? "strikethrough" : ""}
        >
          <TableCell>{index + 1}</TableCell>
          <TableCell align="left">{row.firstName}</TableCell>
          <TableCell align="left">{row.lastName}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">{`${new Date(
            row.createdAt
          ).toLocaleString()}`}</TableCell>
          <TableCell
            align="left"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <span> {`${new Date(row.updatedAt).toLocaleString()}`}</span>
            {row?.role.includes("ambassador") && <ArrowForwardIosIcon sx={{ fontSize: '20px', marginLeft: 'auto', cursor: 'pointer' }} onClick={() => handleRouteToCommunity({referralId: row.referralId})} />}
          </TableCell>
        </StyledTableRow>
      );
    });
  return (
    <>
       <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Admins
      </Typography>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
          <Table
            headCells={headCells}
            loading={loading}
            tableBodyContent={()=>tableBodyContent(admins)}
          />
        </Paper>
      </Box>
 
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Ambassadors
      </Typography>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
          <Table
            headCells={headCells}
            loading={loading}
            tableBodyContent={()=>tableBodyContent(ambassadors)}
          />
        </Paper>
      </Box>
    </>
  );
};
export default Ambassador;