export const getRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = '',
} = {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    'Authorization': `Bearer ${user?.token}`,
    'Content-Type': 'application/json'
  };

  const response = await fetch(`${domain}${url}${url.includes('?') ? '&' : '?'}${params}`, {
    method: 'GET',
    cache: 'no-cache',
    headers,
  });
  return await response.json();
};

export const postRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = '',
  body = {},
} = {}) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await fetch(`${domain}${url}${url.includes('?') ? '&' : '?'}${params}`, {
    method: 'POST',
    headers,
    cache: 'no-cache',
    body: JSON.stringify(body)
  });
  return await response.json();
};

export const putRequest = async ({
  domain = process.env.REACT_APP_API_URL,
  url = '/',
  params = '',
  body = {},
} = {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    'Authorization': `Bearer ${user?.token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(`${domain}${url}${params}`, {
    method: 'PUT',
    headers,
    cache: 'no-cache',
    body: JSON.stringify(body),
  });

  return await response.json();
};

