import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

const StyledLink = styled(NavLink)(({ theme }) => ({
    display: "flex",
    textDecoration: "none",
    color: "inherit",
}));

const StyledListItemButton = styled(ListItemButton)({
    paddingTop: "3px",
    paddingBottom: "3px",
});

const mainList = [
    { to: "/dashboard/users", label: "Users" },
    { to: "/dashboard/contracts", label: "Contracts" },
    { to: "/dashboard/ambassadors", label: "Ambassador" },
];

export const mainListItems = (
    <React.Fragment>
        {mainList?.map(({ to, label }, ind) => (
            <StyledLink to={to} key={ind}>
                <StyledListItemButton >
                    <ListItemIcon sx={{ marginTop: "4px", minWidth: '30px' }}>
                        <ArrowForwardOutlinedIcon sx={{ fontSize: '20px' }} />
                    </ListItemIcon>
                    <ListItemText primary={label} sx={{ '& >span': { fontSize: '14px' } }} />
                </StyledListItemButton>
            </StyledLink>
        ))}
    </React.Fragment>
);
