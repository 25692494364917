import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "../../Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../Table/TableToolbar";
import useCommon from "../../../hooks/common";
import { getRequest } from "../../../utils/httpRequest";
import { styled } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts/PieChart";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useTheme } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";

const headCells = [
  {
    id: "owner",
    numeric: true,
    disablePadding: false,
    label: "No",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "updatedAt",
    numeric: true,
    disablePadding: false,
    label: "Updated At",
  },
];

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

const StyledTile = styled(Box)(({ theme }) => ({
  height: "350px",
  padding: "20px",
  width: "32%",
  backgroundColor: "#1c2024",
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  paddingBottom: "5px",
}));


const getInsights = (insightsCount, totalContracts) => (insightsCount / totalContracts *100).toFixed(1);

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + 40 + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const Contracts = () => {
  const [contracts, setContracts] = useState([]);
  const [metaInfo, setMetaInfo] = useState([]);
  const theme = useTheme();

  const {
    loading,
    days,
    menuItems,
    setLoading,
    daysHandleChange,
  } = useCommon();

  useEffect(() => {
    const getContracts = async () => {
      try {
        setLoading(true);
        let queryParams = "size=0";
        if (days !== "All") queryParams = `${queryParams}&startDate=${days}`;
        const {
          code,
          data,
          message = "",
          meta,
        } = await getRequest({
          url: `/api/v1/ap/user-contracts/getContracts`,
          params: queryParams,
        });
        if (code === 200) {
          setContracts(data);
          setMetaInfo(meta);
          return;
        }
        throw new Error(message);
      } catch (error) {
        console.log("============>", error.message);
      } finally {
        setLoading(false);
      }
    };
    getContracts();
  }, [days]);

  const tableBodyContent = () =>
    contracts.map((row, index) => {
      return (
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell title={row.owner} cID={row._id}>
            {index + 1}
          </TableCell>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.status}</TableCell>
          <TableCell align="left">{`${new Date(
            row.createdAt
          ).toLocaleString()}`}</TableCell>
          <TableCell align="left">{`${new Date(
            row.updatedAt
          ).toLocaleString()}`}</TableCell>
        </TableRow>
      );
    });

  return (
    <>
      <TableToolbar
        title="Contracts"
        label="Days"
        days={days}
        menuItems={menuItems}
        daysHandleChange={daysHandleChange}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        p="0"
        pb="30px"
      >
        <StyledTile color={"main"}>
          <StyledTitle>Total Contracts Count</StyledTitle>

          <PieChart
            series={[
              {
                data: [
                { id: 0, color: "#f2a82c", value: getInsights(metaInfo.deployedContracts, metaInfo.count) },
                { id: 1, color: "#02b2af", value: getInsights(metaInfo.draftContracts, metaInfo.count) },
              ],
                innerRadius: 60,
                outerRadius: 100,
                paddingAngle: 3,
                cornerRadius: 5,
                cx: 150,
                cy: 150,
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
          >
            <PieCenterLabel>{metaInfo.count}</PieCenterLabel>{" "}
          </PieChart>
        </StyledTile>

        <StyledTile color={"main"}>
          <StyledTitle>Drafted Contracts</StyledTitle>
          <Gauge
            value={
              ((metaInfo.draftContracts || 0) / (metaInfo.count || 1)) * 100
            }
            // value={98.4}
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: "#02b2af",
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            }}
            text={() =>
              metaInfo.draftContracts
                ? `${metaInfo.draftContracts || ""} / ${metaInfo.count || ""} `
                : "-"
            }
          />
        </StyledTile>

        <StyledTile color={"main"}>
          <StyledTitle>Deployed Contracts</StyledTitle>
          <Gauge
            value={
              ((metaInfo.deployedContracts || 0) / (metaInfo.count || 1)) * 100
            }
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: "translate(0px, 0px)",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: "#f2a82c",
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            }}
            text={() =>
              metaInfo.deployedContracts
                ? `${metaInfo.deployedContracts || ""} / ${
                    metaInfo.count || ""
                  } `
                : "-"
            }
          />
        </StyledTile>
      </Box>

      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
          <Table
            headCells={headCells}
            loading={loading}
            tableBodyContent={tableBodyContent}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Contracts;
