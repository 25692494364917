import { grey, indigo } from '@mui/material/colors';

const palette = {
  primary: {
    main: '#34C0AC',
    light: '#B1DED3',
    dark: '#101418',
    black: '#000000'
  },
};

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: indigo[900],
          dark: '#08ff41',
          contrastText: '#fff',
        },
        secondary: {
          main: '#008732',
          dark: '#008732',
          contrastText: '#fff',
        },
        divider: grey[400],
        text: {
          primary: grey[800],
          secondary: grey[700],
        },
      }
      : {
        background: {
          default: '#101418',
          paper: palette.primary.dark,
        },

        divider: grey[900],
        text: {
          primary: grey[300],
          secondary: grey[400],
        },
      }),
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    body: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
  },
});