import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Select from "../Select/Select";
import SearchBar from '../SearchBar/SearchBar';

const TableToolbar = ({title, label, days, menuItems, daysHandleChange, searchHandleChange, callingFrom = ''}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {callingFrom === "users" && <SearchBar onChange={searchHandleChange}/>}
      <Select
        label={label}
        days={days}
        menuItems={menuItems}
        handleChange={daysHandleChange}
      />
    </Toolbar>
  );
};

export default TableToolbar;
