import React from 'react';
import Popover from '@mui/material/Popover';
import { styled, Box, Typography } from '@mui/material';

const PopoverContainer = styled(Box)(({theme}) => ({
    width: 180,
    backgroundColor: '#272823',
    color: '#f6f6f6f'
  }));
  
  const ListStyle = styled(Box)(({theme}) => ({
    border: `1px solid #323233`,
    display: 'flex',
    alignItems: 'center',
    gap: 7,
    padding: 6,
    cursor: 'pointer',
    '& > svg':{
      fontSize: 20
    },
    '& > p':{
      font: theme.typography.ubuntuMedium,
      fontSize: 14,
      fontWeight: 500
    }
  }));

const StyledPopover = ({open, anchorEl,list, closePopover, anchorOrigin, transformOrigin, PopoverStyle={}}) => {
  return (
    <Popover
      open={open}
      sx={PopoverStyle}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}>
      <PopoverContainer sx={{backgroundColor: '#1c2024'}}>
        {list?.map(({ name, icon, onClick = () => {} }, ind) => (
          <ListStyle key={ind} onClick={onClick} component={'div'}>
            {icon}
            <Typography>{name}</Typography>
          </ListStyle>
        ))}
      </PopoverContainer>
    </Popover>
  );
};

export default StyledPopover;
